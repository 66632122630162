/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `pazar`,
    TRADE: `ticaret`,
    SPOT: `spot ticaret`,
    OPTIONS: `opsiyon ticareti`,
    CONTRACT: `sözleşme ticareti`,
    ASSET: `varlıklar`,
    COPY_TRADE: `Siparişleri takip edin`,
    LOAN: `borç`,
    USER_CENTER: `Kişisel Merkez`,
    SIGNIN: `Giriş yapmak`,
    SIGNUP: `kayıt olmak`,
    SLOGAN: `TRUST TR`
};
