/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `市场`,
    TRADE: `交易`,
    SPOT: `现货交易`,
    OPTIONS: `期权交易`,
    CONTRACT: `合约交易`,
    ASSET: `资产`,
    COPY_TRADE: `跟单`,
    LOAN: `贷款`,
    USER_CENTER: `个人中心`,
    SIGNIN: `登录`,
    SIGNUP: `注册`,
    SLOGAN: `TRUST TR`
};
